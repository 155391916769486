import router from '@/router';
import policyService from '@/services/policy.service';
import {
  POLICY_ADD,
  POLICY_GET_POLICIES,
  MESSAGE_ERROR,
  MESSAGE_SUCCESS,
  POLICY_GET_BY_REF,
  POLICY_CHECK_RENEWAL,
  POLICY_DOWNLOAD_DOCUMENT,
  POLICY_ACCEPT_RENEWAL,
  POLICY_PAYMENT_UPDATE_SELF_SERVE,
  POLICY_UPDATE_POLICY_DETAILS,
  POLICY_DOWNLOAD_RP_POLICIES,
  POLICY_GET_LETTING_AGENT_RP_POLICIES,
  POLICY_UPDATE_DELIVERY_PREFERENCES,
  POLICY_PAYMENT_UPDATE_DETAILS,
  POLICY_PAYMENT_CONFIRM_PAYMENT,
} from '@/store/types/action-types';
import {
  POLICY_GET_POLICIES_ACTIVE_SUCCESS,
  POLICY_GET_BY_REF_SUCCESS,
  POLICY_GET_POLICIES_CANCELLED_SUCCESS,
  POLICY_GET_BY_REF_FAILURE,
  POLICY_GET_RP_POLICIES_ACTIVE_SUCCESS,
  POLICY_GET_RP_POLICIES_CANCELLED_SUCCESS,
  POLICY_RESET_POLICIES,
} from '@/store/types/mutation-types';
import {
  POLICY_GET_POLICIES_ACTIVE,
  POLICY_GET_POLICIES_CANCELLED,
  POLICY_GET_POLICY_DETAILS,
  POLICY_GET_RP_POLICIES_ACTIVE,
  POLICY_GET_RP_POLICIES_CANCELLED,
} from '@/store/types/getter-types';
import { HOME_PAGE_URL, PAGE_NOT_FOUND_PAGE_URL } from '@/constants/page.constants';
import { POLICY_STATUS } from '@/constants/common.constants';
import { getInsuredAddress } from '@/common/utils';
import { STATUS_CODE } from '@/constants/status-code.constants';

export const policy = {
  state: {
    activePolicies: {
      total: 0,
      policies: [],
    },
    cancelledPolicies: {
      total: 0,
      policies: [],
    },
    activeRPPolicies: {
      total: 0,
      policies: [],
    },
    cancelledRPPolicies: {
      total: 0,
      policies: [],
    },
    policyDetails: {},
  },
  getters: {
    [POLICY_GET_POLICIES_ACTIVE]: (state) => state.activePolicies,
    [POLICY_GET_POLICIES_CANCELLED]: (state) => state.cancelledPolicies,
    [POLICY_GET_RP_POLICIES_ACTIVE]: (state) => state.activeRPPolicies,
    [POLICY_GET_RP_POLICIES_CANCELLED]: (state) => state.cancelledRPPolicies,
    [POLICY_GET_POLICY_DETAILS]: (state) => state.policyDetails,
  },
  actions: {
    [POLICY_ADD]({ dispatch }, data) {
      policyService
        .addPolicy(data)
        .then((res) => {
          if (res && res.isSuccess) {
            setTimeout(() => {
              dispatch(MESSAGE_SUCCESS, { message: res.message });
            }, 200);
            router.push(HOME_PAGE_URL);
          }
        })
        .catch((err) => {
          dispatch(MESSAGE_ERROR, { message: err.message });
          window.scrollTo({ top: 0, behavior: 'smooth' });
        });
    },
    [POLICY_GET_POLICIES]({ commit }, params) {
      policyService
        .getPolicies(params)
        .then((res) => {
          if (res && res.isSuccess) {
            let policies = res.result.items || [];
            policies.forEach((policy) => {
              policy.policyRef = policy.policyRef?.toUpperCase();

              if (policy.policyData) {
                policy.policyData.dataJson.Insurer.Code =
                  policy.policyData.dataJson.Insurer.Code || 'unknown';
                policy.policyData.dataJson.FormattedInsuredAddress = getInsuredAddress(
                  policy.policyData.dataJson.InsuredAddress,
                );
              }
            });
            if (params.Status === POLICY_STATUS.ACTIVE) {
              commit(POLICY_GET_POLICIES_ACTIVE_SUCCESS, {
                total: res.result.total,
                policies: policies,
              });
            } else if (params.Status === POLICY_STATUS.CANCELLED) {
              commit(POLICY_GET_POLICIES_CANCELLED_SUCCESS, {
                total: res.result.total,
                policies: policies,
              });
            }
          }
        })
        .catch(() => {});
    },
    [POLICY_GET_LETTING_AGENT_RP_POLICIES]({ commit }, params) {
      policyService
        .getLettingAgentRPPolicies(params)
        .then((res) => {
          if (res && res.isSuccess) {
            let policies = res.result.items || [];
            policies.forEach((policy) => {
              policy.policyData.dataJson = policyService.buildPolicyDetails(policy);
            });

            if (params.Status === POLICY_STATUS.ACTIVE) {
              commit(POLICY_GET_RP_POLICIES_ACTIVE_SUCCESS, {
                total: res.result.total,
                policies: policies,
              });
            } else if (params.Status === POLICY_STATUS.CANCELLED) {
              commit(POLICY_GET_RP_POLICIES_CANCELLED_SUCCESS, {
                total: res.result.total,
                policies: policies,
              });
            }
          }
        })
        .catch(() => {});
    },
    [POLICY_GET_BY_REF]({ commit }, ref) {
      policyService
        .getPolicyByRef(ref)
        .then((res) => {
          if (res && res.isSuccess) {
            const policyDetails = res.result;
            policyDetails.policyData.dataJson = JSON.parse(policyDetails.policyData.dataJson);
            policyDetails.policyData.dataJson = policyService.buildPolicyDetails(policyDetails);

            commit(POLICY_GET_BY_REF_SUCCESS, policyDetails);
          }
        })
        .catch((error) => {
          commit(POLICY_GET_BY_REF_FAILURE);
          if (error.statusCode === STATUS_CODE.notFound) {
            router.push(PAGE_NOT_FOUND_PAGE_URL);
          }
        });
    },
    async [POLICY_CHECK_RENEWAL](commit, data) {
      return policyService.checkPolicyRenewal(data);
    },
    async [POLICY_ACCEPT_RENEWAL](commit, data) {
      return policyService.acceptPolicyRenewal(data);
    },
    async [POLICY_PAYMENT_UPDATE_SELF_SERVE](commit, data) {
      return policyService.updateSelfServe(data);
    },
    async [POLICY_DOWNLOAD_DOCUMENT](commit, documentId) {
      return policyService.downloadDocument(documentId);
    },
    [POLICY_UPDATE_POLICY_DETAILS]({ commit }, data) {
      commit(POLICY_GET_BY_REF_SUCCESS, data);
    },
    [POLICY_DOWNLOAD_RP_POLICIES](commit, params) {
      policyService
        .downloadRPPolicies(params)
        .then((res) => {
          if (res) {
            const url = window.URL.createObjectURL(new Blob([res]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'PolicyList.csv');
            document.body.appendChild(link);
            link.click();
          }
        })
        .catch(() => {});
    },
    async [POLICY_UPDATE_DELIVERY_PREFERENCES](commit, data) {
      return policyService.updateDeliveryPreferences(data);
    },
    async [POLICY_PAYMENT_UPDATE_DETAILS](commit, data) {
      return policyService.updatePaymentDetails(data);
    },
    async [POLICY_PAYMENT_CONFIRM_PAYMENT](commit, data) {
      return policyService.confirmPayment(data);
    },
  },
  mutations: {
    [POLICY_GET_POLICIES_ACTIVE_SUCCESS](state, policies) {
      state.activePolicies = policies;
    },
    [POLICY_GET_POLICIES_CANCELLED_SUCCESS](state, policies) {
      state.cancelledPolicies = policies;
    },
    [POLICY_GET_RP_POLICIES_ACTIVE_SUCCESS](state, policies) {
      state.activeRPPolicies = policies;
    },
    [POLICY_GET_RP_POLICIES_CANCELLED_SUCCESS](state, policies) {
      state.cancelledRPPolicies = policies;
    },
    [POLICY_GET_BY_REF_SUCCESS](state, policyDetails) {
      state.policyDetails = policyDetails;
    },
    [POLICY_GET_BY_REF_FAILURE](state) {
      state.policyDetails = {};
    },
    [POLICY_RESET_POLICIES](state) {
      state.activePolicies =
        state.cancelledPolicies =
        state.activeRPPolicies =
        state.cancelledRPPolicies =
          {
            total: 0,
            policies: [],
          };
    },
  },
};
