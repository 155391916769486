import {
  ADVISER_SET_CURRENT_SEARCHED_USER,
  ADVISER_GET_RESEND_EMAIL,
  ADVISER_RE_SEND_EMAIL,
  MESSAGE_ERROR,
  MESSAGE_SUCCESS,
} from '@/store/types/action-types';
import {
  ADVISER_SET_CURRENT_SEARCHED_USER_MUTATION,
  ADVISER_REMOVE_CURRENT_SEARCHED_USER_MUTATION,
} from '@/store/types/mutation-types';
import { ADVISER_GET_CURRENT_SEARCHED_USER } from '@/store/types/getter-types';
import userService from '@/services/user.service';
import { HOME_PAGE_URL } from '@/constants/page.constants';
import router from '@/router';
import { CURRENT_SEARCHED_USER } from '@/constants/key.constants';
import Vue from 'vue';

const currentSearchedUser = window.$cookies.get(CURRENT_SEARCHED_USER);

export const adviser = {
  state: {
    currentSearchedUser: currentSearchedUser,
  },
  getters: {
    [ADVISER_GET_CURRENT_SEARCHED_USER]: (state) => state.currentSearchedUser,
  },
  actions: {
    [ADVISER_SET_CURRENT_SEARCHED_USER]({ commit }, email) {
      return userService.getUserByCustomerEmail(email).then((res) => {
        commit(ADVISER_SET_CURRENT_SEARCHED_USER_MUTATION, res.result);
        router.push(HOME_PAGE_URL);
      });
    },
    async [ADVISER_GET_RESEND_EMAIL](commit, data) {
      return userService.adviserGetReSendEmailList(data);
    },
    async [ADVISER_RE_SEND_EMAIL]({ dispatch }, id) {
      return userService
        .adviserResendEmail(id)
        .then((res) => {
          dispatch(MESSAGE_SUCCESS, { message: res.message });
        })
        .catch((err) => {
          dispatch(MESSAGE_ERROR, { message: err.message });
        })
        .finally((res) => {
          return res;
        });
    },
  },
  mutations: {
    [ADVISER_SET_CURRENT_SEARCHED_USER_MUTATION](state, user) {
      Vue.$cookies.set(CURRENT_SEARCHED_USER, user, { expire: 0 });
      state.currentSearchedUser = user;
    },
    [ADVISER_REMOVE_CURRENT_SEARCHED_USER_MUTATION](state) {
      state.currentSearchedUser = null;
    },
  },
};
