import { extend, localize } from 'vee-validate';
import en from 'vee-validate/dist/locale/en.json';
import * as rules from 'vee-validate/dist/rules';
import format from 'string-format';
import { isValid } from 'postcode';
import {
  ERROR_MSG_REQUIRED,
  ERROR_MSG_EMAIL_FORMAT,
  ERROR_MSG_INVALID_FORMAT,
} from '@/constants/message.constants';
import zxcvbn from 'zxcvbn';

// install rules and localization
Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});

extend('required', {
  message: format(ERROR_MSG_REQUIRED, '{_field_}'),
});

extend('email', {
  message: ERROR_MSG_EMAIL_FORMAT,
});

extend('requiredCheckBox', {
  validate: (value) => value,
});

extend('utf8', {
  validate: (value) => {
    for (var i = 0; i < value.length; i++) {
      if (value.charCodeAt(i) > 127) return false;
    }
    return true;
  },
  message: format(ERROR_MSG_INVALID_FORMAT, 'this {_field_}'),
});

extend('postcode', {
  validate: (value) => {
    return isValid(value);
  },
  message: format(ERROR_MSG_INVALID_FORMAT, 'this {_field_}'),
});

extend('pws', {
  validate: (value) => {
    const strength = zxcvbn(value);
    return strength.score > 1;
  },
});

extend('confirm', {
  params: ['target'],
  validate(value, { target }) {
    return value === target;
  },
});

extend('exist', {
  params: ['target'],
  validate(value, { target }) {
    return value !== target;
  },
});

extend('uniq', {
  validate() {
    return false;
  },
});

extend('regex', {
  message: format(ERROR_MSG_INVALID_FORMAT, 'this {_field_}'),
});

localize('en', en);
