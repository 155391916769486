import Vue from 'vue';
import numeral from 'numeral';
import moment from 'moment';
import { DATE_FORMAT_YYYYMMDD } from '@/constants/date.constants';
import { AMOUNT_DEFAULT_FORMAT } from '@/constants/currency-format.constants';
import { getNumberWithOrdinal } from '@/common/utils';

const formatCurrency = Vue.filter('formatCurrency', (val, format) => {
  return '£' + numeral(val).format(format || AMOUNT_DEFAULT_FORMAT);
});

const date = Vue.filter('date', (date, format) => {
  return moment(date, DATE_FORMAT_YYYYMMDD).format(format);
});

const dateTime = Vue.filter('dateTime', (date, format) => {
  return moment(date).format(format);
});

const pluralize = Vue.filter('pluralize', (amount, singular, plural) =>
  amount === 1 ? amount + ` ${singular}` : amount + ` ${plural}`,
);

const truncate = Vue.filter('truncate', (text, length, suffix) => {
  if (text && text.length > length) {
    return text.substring(0, length) + suffix;
  } else {
    return text;
  }
});

const numberOrdinal = Vue.filter('numberOrdinal', (number) => {
  return getNumberWithOrdinal(number);
});

const capitalizeFirstLetter = Vue.filter('capitalizeFirstLetter', (val) => {
  return val.charAt(0).toUpperCase() + val.toLowerCase().slice(1);
});

const capitalizeFirstLetterEachWord = Vue.filter('capitalizeFirstLetterEachWord', (val) => {
  if (!val) return '';
  return val.replace(/\w\S*/g, (word) => {
    return word.charAt(0).toUpperCase() + word.toLowerCase().slice(1);
  });
});

export default {
  formatCurrency,
  date,
  dateTime,
  truncate,
  pluralize,
  numberOrdinal,
  capitalizeFirstLetter,
  capitalizeFirstLetterEachWord,
};
