import Vue from 'vue';
import VueCookies from 'vue-cookies';
import App from '@/App.vue';
import vuetify from '@/plugins/vuetify';
import IdleVue from 'idle-vue';
import '@/plugins/validation';
import '@/plugins/perfect-scroll';
import '@/plugins/mask';
import filters from '@/filters';
import router from './router';
import store from './store';
import { IDLE_TIME } from '@/constants/common.constants';

Vue.config.productionTip = false;

if (process.env.NODE_ENV === 'production') {
  Vue.config.devtools = false;
  Vue.config.debug = false;
  Vue.config.silent = true;
}

// cookie storage
Vue.use(VueCookies);

// timer idle
const eventsHub = new Vue();
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  idleTime: IDLE_TIME,
});

new Vue({
  router,
  store,
  vuetify,
  filters,
  render: (h) => h(App),
}).$mount('#app');
