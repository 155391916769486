<template>
  <v-app class="app-ps" :class="{ 'app__bg--white': hasBackgroundImg }">
    <TheHeader />

    <main>
      <router-view />
    </main>

    <TheFooter />
  </v-app>
</template>

<script>
import TheHeader from '@/components/TheHeader.vue';
import TheFooter from '@/components/TheFooter.vue';
import { generateGreetings } from '@/common/utils';
import { SETTING_SET_GREETING } from '@/store/types/action-types';
import { AUTHENTICATION_GET_USER_INFO } from '@/store/types/getter-types';
import { mapGetters } from 'vuex';
import { updateHelpshiftConfig } from '@/common/utils';
import { AUTHENTICATION_LOGOUT } from '@/store/types/action-types';

export default {
  name: 'App',
  components: { TheHeader, TheFooter },
  computed: {
    ...mapGetters({
      user: AUTHENTICATION_GET_USER_INFO,
    }),
  },
  data() {
    return {
      hasBackgroundImg: false,
    };
  },
  watch: {
    $route() {
      this.hasBackgroundImg = this.$route.meta.hasBackgroundImg;
    },
  },
  onIdle() {
    if (this.user) {
      this.$store.dispatch(AUTHENTICATION_LOGOUT);
    }
  },
  created() {
    const { dispatch } = this.$store;
    this.interval = setInterval(() => {
      const greeting = generateGreetings();
      dispatch(SETTING_SET_GREETING, greeting);
    }, 30000);

    updateHelpshiftConfig(this.user);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>

<style lang="scss">
#app {
  font-family: 'MontserratRegular', sans-serif;
  color: $text-color;
}
</style>
