import http from '@/common/http-common';
import {
  API_URL_REGISTER,
  API_URL_ACTIVE_ACCOUNT,
  API_URL_FORGOT_PASSWORD,
  API_URL_RESET_PASSWORD,
  API_URL_CHANGE_PASSWORD,
  API_URL_CHANGE_EMAIL,
  API_URL_VERIFY_EMAIL,
  API_URL_VALIDATE_TOKEN,
  API_URL_CHECK_EMAIL,
  API_URL_USER_RESEND_EMAIL,
  API_URL_COMPLETE_REGISTRATION,
  API_URL_CHANGE_NAME,
  API_URL_GET_USER_BY_CUSTOMER_EMAIL,
  API_URL_GET_CURRENT_USER,
  API_URL_ADVISER_GET_RESEND_EMAIL,
  API_URL_ADVISER_RESEND_EMAIL,
  API_URL_CHANGE_EMAIL_BY_ADVISER,
} from '@/constants/api.constants';

class UserService {
  register(data) {
    return http.post(API_URL_REGISTER, data);
  }

  activeAccount(data) {
    return http.post(API_URL_ACTIVE_ACCOUNT, data);
  }

  forgotPassword(email) {
    return http.post(API_URL_FORGOT_PASSWORD, email);
  }

  createNewPassword(data) {
    return http.post(API_URL_RESET_PASSWORD, data);
  }

  changePassword(data) {
    return http.post(API_URL_CHANGE_PASSWORD, data);
  }

  changeEmail({ newEmail, isUpdateByAdviser, policyHolderEmail }) {
    const apiUrl = isUpdateByAdviser ? API_URL_CHANGE_EMAIL_BY_ADVISER : API_URL_CHANGE_EMAIL;
    const rqData = isUpdateByAdviser ? { newEmail, policyHolderEmail } : { newEmail };
    return http.post(apiUrl, rqData);
  }

  verifyEmail(verifyData) {
    return http.post(API_URL_VERIFY_EMAIL, verifyData);
  }

  validateToken(data) {
    return http.post(API_URL_VALIDATE_TOKEN, data);
  }

  checkEmail(data) {
    return http.post(API_URL_CHECK_EMAIL, data, { ignoreLoading: true });
  }

  userResendEmail(data) {
    return http.post(API_URL_USER_RESEND_EMAIL, data);
  }

  completeRegistration(data) {
    return http.post(API_URL_COMPLETE_REGISTRATION, data);
  }

  changeName(data) {
    return http.post(API_URL_CHANGE_NAME, data);
  }

  getUserByCustomerEmail(email) {
    if (!email) {
      return http.get(API_URL_GET_CURRENT_USER);
    }
    return http.get(`${API_URL_GET_USER_BY_CUSTOMER_EMAIL}?email=${encodeURIComponent(email)}`);
  }

  adviserGetReSendEmailList(params) {
    return http.get(API_URL_ADVISER_GET_RESEND_EMAIL, { params });
  }

  adviserResendEmail(id) {
    return http.post(API_URL_ADVISER_RESEND_EMAIL, id);
  }
}

export default new UserService();
