/* eslint-disable prettier/prettier */
export const AUTHENTICATION_LOGIN_REQUEST = 'AUTHENTICATION_LOGIN_REQUEST';
export const AUTHENTICATION_LOGIN_SUCCESS = 'AUTHENTICATION_LOGIN_SUCCESS';
export const AUTHENTICATION_LOGIN_FAILURE = 'AUTHENTICATION_LOGIN_FAILURE';
export const AUTHENTICATION_LOGOUT_SUCCESS = 'AUTHENTICATION_LOGOUT_SUCCESS';
export const AUTHENTICATION_REFRESH_TOKEN_SUCCESS = 'AUTHENTICATION_REFRESH_TOKEN_SUCCESS';
export const AUTHENTICATION_REFRESH_TOKEN_FAILURE = 'AUTHENTICATION_REFRESH_TOKEN_FAILURE';

export const SETTING_SET_LOADING_MUTATION = 'SETTING_SET_LOADING_MUTATION';
export const SETTING_SET_GREETING_MUTATION = 'SETTING_SET_GREETING_MUTATION';
export const SETTING_SET_REQ_DATA_MUTATION = 'SETTING_SET_REQ_DATA_MUTATION';
export const SETTING_SET_LOADING_ID_MUTATION = 'SETTING_SET_LOADING_ID_MUTATION';

export const MESSAGE_SUCCESS_MUTATION = 'MESSAGE_SUCCESS';
export const MESSAGE_ERROR_MUTATION = 'MESSAGE_ERROR';
export const MESSAGE_CLEAR_MUTATION = 'MESSAGE_CLEAR';

export const POLICY_GET_POLICIES_ACTIVE_SUCCESS = 'POLICY_GET_POLICIES_ACTIVE_SUCCESS';
export const POLICY_GET_POLICIES_CANCELLED_SUCCESS = 'POLICY_GET_POLICIES_CANCELLED_SUCCESS';
export const POLICY_GET_RP_POLICIES_ACTIVE_SUCCESS = 'POLICY_GET_RP_POLICIES_ACTIVE_SUCCESS';
export const POLICY_GET_RP_POLICIES_CANCELLED_SUCCESS = 'POLICY_GET_RP_POLICIES_CANCELLED_SUCCESS';
export const POLICY_GET_BY_REF_SUCCESS = 'POLICY_GET_BY_REF_SUCCESS';
export const POLICY_GET_BY_REF_FAILURE = 'POLICY_GET_BY_REF_FAILURE';
export const POLICY_RESET_POLICIES = "POLICY_RESET_POLICIES"

export const ADVISER_SET_CURRENT_SEARCHED_USER_MUTATION = 'ADVISER_SET_CURRENT_SEARCHED_USER_MUTATION'
export const ADVISER_REMOVE_CURRENT_SEARCHED_USER_MUTATION = "ADVISER_REMOVE_CURRENT_SEARCHED_USER_MUTATION"
