<template>
  <footer class="app-footer">
    <v-container class="app-container app-container--width-max">
      <div class="footer-link d-flex justify-md-space-between flex-column flex-md-row mb-9">
        <router-link :to="homeLink" class="text-primary text-decoration-none text-link-hover">
          Home
        </router-link>

        <a
          class="text-primary text-decoration-none text-link-hover"
          :href="FAQS_LINK"
          target="_blank"
        >
          FAQs
          <v-icon class="mb-1" size="16"> {{ mdiOpenInNew }} </v-icon>
        </a>

        <span
          class="text-link text-link-hover"
          @click="showDialog('WEBSITE TERMS & CONDITIONS', DIALOG_TYPE.TERMS_AND_CONDITIONS)"
        >
          Terms & conditions
        </span>

        <span class="text-link text-link-hover" @click="showDialog('COOKIES', DIALOG_TYPE.COOKIES)">
          Cookies
        </span>

        <span
          class="text-link text-link-hover"
          @click="showDialog('ACCESSIBILITY', DIALOG_TYPE.ACCESSIBILITY)"
        >
          Accessibility
        </span>

        <span
          class="text-link text-link-hover"
          @click="showDialog('FAIR PROCESSING NOTICE', DIALOG_TYPE.FAIR_PROCESSING_NOTICE)"
        >
          Fair processing notice
        </span>

        <span
          class="text-link text-link-hover"
          @click="showDialog('MODERN SLAVERY ACT', DIALOG_TYPE.MODERN_SLAVERY_ACT)"
        >
          Modern slavery act
        </span>

        <router-link to="/contact-us" class="text-primary text-decoration-none text-link-hover">
          Contact us
        </router-link>

        <div class="hidden-md-and-up text-center">
          <v-btn icon class="mx-auto" @click="linkToTwitter" aria-label="twitter">
            <v-icon class="bg-white icon-twitter--size-bigger">icon-twitter</v-icon>
          </v-btn>
        </div>
      </div>

      <div class="hidden-sm-and-down mb-9">
        <div class="d-md-flex justify-space-between">
          <div class="d-flex align-center">
            <img
              src="@/assets/images/logo-transparent.svg"
              alt="Paymentshield White Logo"
              width="163"
              height="40"
              class="logo mr-4"
            />
            <span class="text--size-small">© Paymentshield Limited 2022</span>
          </div>
          <div>
            <span class="text--size-small">Follow us</span>
            <v-btn icon class="ml-2 d-inline-block" @click="linkToTwitter" aria-label="twitter">
              <v-icon class="bg-white">icon-twitter</v-icon>
            </v-btn>
          </div>
        </div>
      </div>

      <p class="text--size-tiny text-center text-md-left px-8 px-md-0">
        Paymentshield Limited (registered number 02728936) is a company registered in England and
        Wales at Southport Business Park, Wight Moss Way, Southport, PR8 4HQ. VAT registration no:
        GB 447284724. Telephone calls may be recorded for security purposes and monitored under our
        quality control procedures. Paymentshield Limited is authorised and regulated by the
        Financial Conduct Authority (FCA) under registration No. 312708.See the Financial Services
        Register for further details.
      </p>

      <div class="hidden-md-and-up text-center mt-11">
        <img
          src="@/assets/images/logo-transparent.svg"
          alt="Paymentshield White Logo"
          class="logo mb-8"
          width="163"
          height="40"
        />
        <p class="text--size-small">© Paymentshield Limited 2022</p>
      </div>
    </v-container>

    <BaseDialog ref="dialog">
      <template v-slot:content="{ isShow }">
        <TermsAndConditions
          v-if="dialogType === DIALOG_TYPE.TERMS_AND_CONDITIONS"
          :isShow="isShow"
        />

        <FairProcessingNotice
          v-else-if="dialogType === DIALOG_TYPE.FAIR_PROCESSING_NOTICE"
          :isShow="isShow"
        />

        <AccessibilityDialog
          v-else-if="dialogType === DIALOG_TYPE.ACCESSIBILITY"
          :isShow="isShow"
        />

        <ModernSlaveryAct
          v-else-if="dialogType === DIALOG_TYPE.MODERN_SLAVERY_ACT"
          :isShow="isShow"
        />

        <CookiesDialog v-else-if="dialogType === DIALOG_TYPE.COOKIES" :isShow="isShow" />
      </template>
    </BaseDialog>
  </footer>
</template>

<script>
import { FAQS_LINK, TWITTER_LINK } from '@/constants/external-link.constants';
import { DIALOG_TYPE } from '@/constants/common.constants';
import BaseDialog from '@/components/BaseDialog';
import { HOME_PAGE_URL, ADVISER_HOME_PAGE_URL } from '@/constants/page.constants';
import { mapGetters } from 'vuex';
import { AUTHENTICATION_GET_USER_INFO } from '@/store/types/getter-types';
import { USER_TYPE } from '@/constants/common.constants';
import { mdiOpenInNew } from '@mdi/js';

export default {
  name: 'TheFooter',
  components: {
    BaseDialog,
    TermsAndConditions: () => import('@/views/Dialog/TermsAndConditions'),
    FairProcessingNotice: () => import('@/views/Dialog/FairProcessingNotice'),
    AccessibilityDialog: () => import('@/views/Dialog/AccessibilityDialog'),
    ModernSlaveryAct: () => import('@/views/Dialog/ModernSlaveryAct'),
    CookiesDialog: () => import('@/views/Dialog/CookiesDialog'),
  },
  data() {
    return {
      dialogType: '',
      FAQS_LINK,
      DIALOG_TYPE,
      HOME_PAGE_URL,
      ADVISER_HOME_PAGE_URL,
      USER_TYPE,
      mdiOpenInNew,
    };
  },
  methods: {
    showDialog(title, type) {
      this.dialogType = type;
      this.$refs.dialog.open(title);
    },
    linkToTwitter() {
      window.open(TWITTER_LINK, '_blank');
    },
  },
  computed: {
    ...mapGetters({
      currentUser: AUTHENTICATION_GET_USER_INFO,
    }),
    homeLink() {
      if (this.currentUser && this.currentUser?.userTypeKey === this.USER_TYPE.ADVISER) {
        return this.ADVISER_HOME_PAGE_URL;
      } else {
        return this.HOME_PAGE_URL;
      }
    },
  },
  mounted() {
    this.$root.$on('openDialog', ({ title, type }) => {
      this.showDialog(title, type);
    });
  },
};
</script>

<style lang="scss" scoped>
.app-footer {
  background-color: $black-2;
  max-height: 253px;
  padding: 43px 0 40px;
  z-index: 2;
  position: relative;

  .app-container {
    background-color: $black-2;

    * {
      color: $white;
    }
  }

  .footer-link {
    font-family: 'MontserratMedium';
  }
}

@media screen and (max-width: ($grid-breakpoints-lg - 1)) {
  .app-footer {
    max-height: 100%;

    .footer-link {
      font-family: 'MontserratRegular';
      font-size: 23px;
      text-align: center;

      a,
      .text-link {
        margin-bottom: 28px;
      }
    }
  }
}
</style>
