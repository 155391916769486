import router from '@/router';
import { USER_INFO } from '@/constants/key.constants';
import authService from '@/services/auth.service';
import policyService from '@/services/policy.service';

import {
  AUTHENTICATION_LOGIN,
  AUTHENTICATION_LOGOUT,
  AUTHENTICATION_REFRESH_TOKEN,
  MESSAGE_ERROR,
  AUTHENTICATION_UPDATE_USER_INFO,
  POLICY_DOCUMENT_PULL,
} from '@/store/types/action-types';
import {
  AUTHENTICATION_LOGIN_FAILURE,
  AUTHENTICATION_LOGIN_REQUEST,
  AUTHENTICATION_LOGIN_SUCCESS,
  AUTHENTICATION_LOGOUT_SUCCESS,
  AUTHENTICATION_REFRESH_TOKEN_FAILURE,
  AUTHENTICATION_REFRESH_TOKEN_SUCCESS,
  SETTING_SET_REQ_DATA_MUTATION,
  ADVISER_REMOVE_CURRENT_SEARCHED_USER_MUTATION,
} from '@/store/types/mutation-types';
import { HOME_PAGE_URL, ADVISER_HOME_PAGE_URL } from '@/constants/page.constants';
import {
  AUTHENTICATION_GET_USER_INFO,
  AUTHENTICATION_GET_LOGGED_IN,
} from '@/store/types/getter-types';
import { TOKEN_PURPOSE, USER_TYPE } from '@/constants/common.constants';

const user = window.$cookies.get(USER_INFO);
const initialState = user ? { status: { loggedIn: true }, user } : { status: {}, user: null };

export const authentication = {
  state: initialState,
  getters: {
    [AUTHENTICATION_GET_USER_INFO]: (state) => state.user,
    [AUTHENTICATION_GET_LOGGED_IN]: (state) => (state.status ? state.status.loggedIn : false),
  },
  actions: {
    [AUTHENTICATION_LOGIN]({ commit, dispatch }, { email, password, isRemember }) {
      commit(AUTHENTICATION_LOGIN_REQUEST, { user: email });
      authService
        .login({ email, password })
        .then((res) => {
          if (res && res.isSuccess) {
            const userInfo = res.result;
            authService.setUserData(userInfo, isRemember);
            commit(AUTHENTICATION_LOGIN_SUCCESS, userInfo);

            if (userInfo.userTypeKey === USER_TYPE.POLICYHOLDER) {
              router.push(HOME_PAGE_URL);
            } else {
              router.push(ADVISER_HOME_PAGE_URL);
            }

            // trigger job to get all policy documents
            dispatch(POLICY_DOCUMENT_PULL);
          }
        })
        .catch((error) => {
          authService.clearUserData();
          commit(AUTHENTICATION_LOGIN_FAILURE);
          commit(SETTING_SET_REQ_DATA_MUTATION, {
            email: email,
            purpose: TOKEN_PURPOSE.EMAIL_CONFIRMATION,
          });
          dispatch(MESSAGE_ERROR, { message: error.message });
          window.scrollTo({ top: 0, behavior: 'smooth' });
        });
    },
    [AUTHENTICATION_LOGOUT]({ commit }) {
      authService.logout();
      commit(AUTHENTICATION_LOGOUT_SUCCESS);
      commit(ADVISER_REMOVE_CURRENT_SEARCHED_USER_MUTATION);
    },
    [AUTHENTICATION_UPDATE_USER_INFO]({ commit }, { userInfo, isRemember }) {
      authService.setUserData(userInfo, isRemember);
      commit(AUTHENTICATION_LOGIN_SUCCESS, userInfo);
    },
    async [AUTHENTICATION_REFRESH_TOKEN]({ commit }, refreshToken) {
      refreshToken = refreshToken || authService.getRememberMeToken();
      return authService
        .refreshToken(refreshToken)
        .then((res) => {
          if (res && res.isSuccess) {
            const userInfo = res.result;
            const isRemember = !!authService.getRememberMeToken();
            authService.setUserData(userInfo, isRemember);
            commit(AUTHENTICATION_REFRESH_TOKEN_SUCCESS, userInfo);
          }
        })
        .catch((err) => {
          commit(AUTHENTICATION_REFRESH_TOKEN_FAILURE, err.message);
        });
    },
    [POLICY_DOCUMENT_PULL]() {
      policyService
        .documentPull()
        .then(() => {})
        .catch(() => {});
    },
  },
  mutations: {
    [AUTHENTICATION_LOGIN_REQUEST](state, user) {
      state.status = { loggingIn: true };
      state.user = user;
    },
    [AUTHENTICATION_LOGIN_SUCCESS](state, user) {
      state.status = { loggedIn: true };
      state.user = user;
    },
    [AUTHENTICATION_LOGIN_FAILURE](state) {
      state.status = {};
      state.user = null;
    },
    [AUTHENTICATION_LOGOUT_SUCCESS](state) {
      state.status = {};
      state.user = null;
    },
    [AUTHENTICATION_REFRESH_TOKEN_SUCCESS](state, user) {
      state.status = { loggedIn: true };
      state.user = user;
    },
    [AUTHENTICATION_REFRESH_TOKEN_FAILURE](state) {
      state.status = {};
      state.user = null;
    },
  },
};
