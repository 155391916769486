<template>
  <div>
    <v-app-bar dark flat height="80">
      <v-toolbar-title>
        <router-link :to="homeLink">
          <img
            class="logo"
            src="@/assets/images/logo-transparent.svg"
            width="163"
            height="40"
            alt="Paymentshield White Logo"
          />
        </router-link>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-app-bar-nav-icon
        v-if="loggedIn"
        right
        @click.stop="toggleMenu()"
        aria-label="toggle-menu"
      ></v-app-bar-nav-icon>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      @input="toggleMenu(true)"
      app
      right
      temporary
      width="400"
      class="v-navigation-drawer-menu"
    >
      <div class="drawer-header d-flex align-center px-6">
        <h4 class="text-bold">MENU</h4>
        <v-btn icon @click.stop="toggleMenu()" class="btn-close" aria-label="close-button">
          <v-icon size="32">{{ mdiClose }}</v-icon>
        </v-btn>
      </div>

      <v-divider></v-divider>

      <v-list nav class="px-0 py-0">
        <template v-for="item in menuItems">
          <v-list-item link :key="item.id" @click="navigateTo(item)" class="mb-0 pl-4">
            <v-list-item-icon class="align-self-center mr-4">
              <v-icon color="#00838a">{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title class="h4 text-black2">
                {{ item.title }}
                <v-icon class="mb-1" v-if="item.icon2" size="18" color="#cccccc">
                  {{ item.icon2 }}
                </v-icon>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider :key="`${item.id}-divider`"></v-divider>
        </template>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { AUTHENTICATION_LOGOUT } from '@/store/types/action-types';
import {
  ACCOUNT_SETTING_PAGE_URL,
  HOME_PAGE_URL,
  ADD_POLICY_PAGE_URL,
  CONTACT_US_PAGE_URL,
  RESEND_EMAIL_PAGE_URL,
  ADVISER_HOME_PAGE_URL,
} from '@/constants/page.constants';
import router from '@/router';
import { mapGetters } from 'vuex';
import {
  AUTHENTICATION_GET_USER_INFO,
  AUTHENTICATION_GET_LOGGED_IN,
  ADVISER_GET_CURRENT_SEARCHED_USER,
} from '@/store/types/getter-types';
import { togglePageScrollbar } from '@/common/utils';
import { FAQS_LINK } from '@/constants/external-link.constants';
import { USER_TYPE } from '@/constants/common.constants';
import { mdiClose, mdiEmail, mdiOpenInNew } from '@mdi/js';
import sortBy from 'lodash-es/sortBy';

export default {
  name: 'TheHeader',
  data() {
    return {
      drawer: false,
      group: null,
      FAQS_LINK,
      mdiClose,
      USER_TYPE,
      HOME_PAGE_URL,
      ADVISER_HOME_PAGE_URL,
      mdiOpenInNew,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: AUTHENTICATION_GET_USER_INFO,
      loggedIn: AUTHENTICATION_GET_LOGGED_IN,
      currentSearchedUser: ADVISER_GET_CURRENT_SEARCHED_USER,
    }),
    menuItems() {
      let menuItems = [];
      const userType = this.currentUser?.userTypeKey;
      if (userType === USER_TYPE.POLICYHOLDER) {
        menuItems = [
          { id: 1, title: 'Home', icon: 'icon-home', page: HOME_PAGE_URL },
          { id: 2, title: 'Add a policy', icon: 'icon-note-add', page: ADD_POLICY_PAGE_URL },
          { id: 3, title: 'FAQs', icon: 'icon-live-help', icon2: this.mdiOpenInNew },
          { id: 4, title: 'Contact us', icon: mdiEmail, page: CONTACT_US_PAGE_URL },
          // eslint-disable-next-line prettier/prettier
          { id: 5, title: 'Account settings', icon: 'icon-setting', page: ACCOUNT_SETTING_PAGE_URL },
          { id: 6, title: 'Log out', icon: 'icon-logout' },
        ];
      } else if (userType === USER_TYPE.ADVISER) {
        menuItems = [
          { id: 1, title: 'Home', icon: 'icon-home', page: ADVISER_HOME_PAGE_URL },
          { id: 6, title: 'Log out', icon: 'icon-logout' },
        ];

        if (this.currentSearchedUser) {
          menuItems.push(
            { id: 2, title: 'Manage emails', icon: mdiEmail, page: RESEND_EMAIL_PAGE_URL },
            // eslint-disable-next-line prettier/prettier
            { id: 5, title: 'Manage account', icon: 'icon-setting', page: ACCOUNT_SETTING_PAGE_URL },
          );
          menuItems = sortBy(menuItems, ['id']);
        }
      }
      return menuItems;
    },
    homeLink() {
      if (this.currentUser && this.currentUser?.userTypeKey === this.USER_TYPE.ADVISER) {
        return this.ADVISER_HOME_PAGE_URL;
      } else {
        return this.HOME_PAGE_URL;
      }
    },
  },
  methods: {
    navigateTo(item) {
      this.drawer = false;
      if (item.id === 6) {
        const { dispatch } = this.$store;
        dispatch(AUTHENTICATION_LOGOUT, this.form);
      } else if (item.id === 3) {
        window.open(this.FAQS_LINK, '_blank');
      } else {
        router.push(item.page);
      }
    },
    toggleMenu(fromDrawer) {
      if (!fromDrawer) {
        this.drawer = !this.drawer;
      }

      // Handle show/hide the scrollbar of the page when open/close the drawer
      togglePageScrollbar(this.drawer);
    },
  },
  beforeDestroy() {
    // Remove 'hide-scroll' class in the HTML tag (Fix bug: when clicking to back/next button in the menu of the browser)
    togglePageScrollbar(false);
  },
};
</script>

<style lang="scss" scoped>
.v-list-item {
  min-height: 72.8px;
}

.v-size--default.v-btn--round {
  &.v-btn--icon {
    .icon-notification {
      height: 20px;
      width: 20px;
    }
  }
}
</style>
