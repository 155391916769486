import {
  SETTING_SET_LOADING,
  SETTING_SET_GREETING,
  SETTING_SET_REQ_DATA,
  SETTING_SET_LOADING_ID,
  SETTING_GET_BUSINESS_TIMES,
} from '@/store/types/action-types';
import {
  SETTING_SET_LOADING_MUTATION,
  SETTING_SET_GREETING_MUTATION,
  SETTING_SET_REQ_DATA_MUTATION,
  SETTING_SET_LOADING_ID_MUTATION,
} from '@/store/types/mutation-types';
import {
  SETTING_GET_BG_STATUS,
  SETTING_GET_GREETING,
  SETTING_GET_LOADING,
  SETTING_GET_REQ_DATA,
  SETTING_GET_LOADING_ID,
} from '@/store/types/getter-types';
import { generateGreetings } from '@/common/utils';
import settingService from '@/services/setting.service';

const greeting = generateGreetings();

export const setting = {
  state: {
    hasBackground: false,
    greeting,
    isLoading: false,
    reqData: null,
    loadingId: null,
  },
  getters: {
    [SETTING_GET_BG_STATUS]: (state) => state.hasBackground,
    [SETTING_GET_GREETING]: (state) => state.greeting,
    [SETTING_GET_LOADING]: (state) => state.isLoading,
    [SETTING_GET_REQ_DATA]: (state) => state.reqData,
    [SETTING_GET_LOADING_ID]: (state) => state.loadingId,
  },
  actions: {
    [SETTING_SET_LOADING]({ commit }, isLoading) {
      commit(SETTING_SET_LOADING_MUTATION, isLoading);
    },
    [SETTING_SET_GREETING]({ commit }, greeting) {
      commit(SETTING_SET_GREETING_MUTATION, greeting);
    },
    [SETTING_SET_REQ_DATA]({ commit }, setting) {
      commit(SETTING_SET_REQ_DATA_MUTATION, setting.reqData);
    },
    [SETTING_SET_LOADING_ID]({ commit }, loadingId) {
      commit(SETTING_SET_LOADING_ID_MUTATION, loadingId);
    },
    async [SETTING_GET_BUSINESS_TIMES]() {
      return settingService.getBusinessTimes();
    },
  },
  mutations: {
    [SETTING_SET_LOADING_MUTATION](state, isLoading) {
      state.isLoading = isLoading;
      if (!isLoading) {
        state.loadingId = null;
      }
    },
    [SETTING_SET_GREETING_MUTATION](state, greeting) {
      state.greeting = greeting;
    },
    [SETTING_SET_REQ_DATA_MUTATION](state, reqData) {
      state.reqData = reqData;
    },
    [SETTING_SET_LOADING_ID_MUTATION](state, loadingId) {
      state.loadingId = loadingId;
    },
  },
};
