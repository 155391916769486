export const HOME_PAGE_URL = '/home';
export const LOGIN_PAGE_URL = '/login';
export const ACTIVE_ACCOUNT_PAGE_URL = '/register/active';
export const REGISTER_PAGE_URL = '/register';
export const REGISTER_SUCCESS_PAGE_URL = '/register-success';
export const ACCOUNT_SETTING_PAGE_URL = '/account-setting';
export const CHANGE_PASSWORD_PAGE_URL = '/account-setting/change-password';
export const CHANGE_EMAIL_PAGE_URL = '/account-setting/change-email';
export const CHANGE_EMAIL_SUMMARY_PAGE_URL = '/account-setting/change-email-summary';
export const CHANGE_NAME_PAGE_URL = '/account-setting/change-name';
export const FORGOT_PASSWORD_CHECK_EMAIL = '/forgot-password/check-email';
export const FORGOT_PASSWORD_PAGE_URL = '/forgot-password';
export const ADD_POLICY_PAGE_URL = '/add-policy';
export const POLICY_DETAILS_PAGE_URL = '/policy/{0}';
export const POLICY_DOCUMENT_PAGE_URL = '/document';
export const PAGE_NOT_FOUND_PAGE_URL = '/error-404';
export const CONTACT_US_PAGE_URL = '/contact-us';
export const RESEND_EMAIL_PAGE_URL = '/resend-email';
export const ADVISER_HOME_PAGE_URL = '/adviser/home';
export const EDIT_PAYMENT_PAGE_URL = `/policy/{0}/edit-payment`;
