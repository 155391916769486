/* eslint-disable prettier/prettier */
export const ERROR_MSG_REQUIRED = '{0} is required.';
export const ERROR_MSG_EMAIL_FORMAT = 'Please check the format of your email address and try again.';
export const ERROR_MSG_CONFIRM = 'Confirm password does not match.';
export const ERROR_MSG_PASS_LENGTH_MIN = 'Please create a strong password, which is at least {0} characters long';
export const ERROR_MSG_PASS_LENGTH_MAX = 'Sorry, your password cannot be longer than {0} characters.';
export const ERROR_MSG_NEW_PASSWORD_MATCH_EXISTING_PASSWORD = 'Your new password must be different to your existing password.';
export const ERROR_MSG_EMAIL_ADDRESSES_DO_NOT_MATCH = 'Email addresses do not match.';
export const ERROR_MSG_REGEX_NAME = `Sorry, we can’t store a name containing the characters {0}, please replace them with a space or an alternative.`;
export const ERROR_MSG_NAME_LENGTH_MAX = `Sorry, we can’t store a name longer than {0} characters.`;
export const ERROR_MSG_UTF8 = `Sorry, your {0} contains some invalid characters.`;
export const ERROR_MSG_DOB = `Sorry, we can’t create an account for you, if you’re not between 18 and 110 years old.`;
export const ERROR_MSG_INVALID_FORMAT = `Sorry, <span class="text-lowercase">{0}</span> isn’t valid. Please try again.`;
export const ERROR_MSG_INVALID_POLICY = `Sorry, the details you've added don't match our records. Please check and try again.`;
export const ERROR_MSG_INTERNAL_SERVER_ERROR= `<span class="text-medium text-black">Sorry, something went wrong.</span> Please try again.`;
export const ERROR_MSG_DUPLICATE_EMAIL= `<span class="text-medium text-black">This email address already exists.</span><br>
  <span class="text-black text-regular">Please <a href="/login">login</a>.</span>`;
export const ERROR_MSG_DUPLICATE_NEW_EMAIL= `<span class="text-medium text-black">Sorry, this email is already in use.</span><span class="text-black text-regular"> If you still want to change your email address, please choose a different one.</span>`;
export const RENEWAL_ERROR ="We're sorry we can't locate your renewal information right now. Please try again later.";
export const ERROR_MSG_CANCEL_CHANGE_PAYMENT = `<span class="text-bold">Change cancelled. </span>Your payment details were not updated.`;
export const ERROR_MSG_INVALID="{0} is invalid.";
export const ERROR_MSG_DOWNLOAD_FILE=`<span class="text-bold text-black">Download failed!</span> Please try again.`;
export const TEXT_BOLD_CONTENT=`<span class="text-bold"> {0} </span> `;
