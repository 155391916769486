import authService from '@/services/auth.service';
import axios from 'axios';
import store from '@/store';
import { AUTHENTICATION_UPDATE_USER_INFO, SETTING_SET_LOADING } from '@/store/types/action-types';
import { ERROR_MSG_INTERNAL_SERVER_ERROR } from '@/constants/message.constants';
import { STATUS_CODE } from '@/constants/status-code.constants';

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

const axiosClient = axios.create({
  baseURL: process.env.VUE_APP_BASE_API_URL,
  headers: {
    'Content-type': 'application/json',
  },
});

axiosClient.interceptors.request.use((request) => {
  if (!request?.ignoreLoading) {
    store.dispatch(SETTING_SET_LOADING, true);
  }
  return requestHandler(request);
});

axiosClient.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error),
);

const requestHandler = (request) => {
  const token = authService.getToken();
  if (token) {
    request.headers.common['Authorization'] = `Bearer ${token}`;
  }

  return request;
};

const successHandler = (response) => {
  if (!response.config?.ignoreLoading) store.dispatch(SETTING_SET_LOADING, false);
  if (response.config?.responseType === 'blob') {
    return response;
  }
  return response.data;
};

const errorHandler = (error) => {
  /*
   * When response code is 401, try to refresh the token .
   * Eject the interceptor so it doesn't loop in case
   * token refresh causes the 401 response
   */
  const originalRequest = error.config;
  if (!error.response) {
    if (!error?.config?.ignoreLoading) store.dispatch(SETTING_SET_LOADING, false);
    return Promise.reject({ message: ERROR_MSG_INTERNAL_SERVER_ERROR });
  }

  if (error.response.status === STATUS_CODE.unauthorized && !originalRequest._retry) {
    if (isRefreshing) {
      return new Promise((resolve, reject) => {
        failedQueue.push({ resolve, reject });
      })
        .then((token) => {
          originalRequest.headers['Authorization'] = `Bearer ${token}`;
          return axiosClient.request(originalRequest);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    }

    originalRequest._retry = true;
    isRefreshing = true;
    const refreshToken = authService.getRefreshToken();

    return new Promise((resolve, reject) => {
      authService
        .refreshToken(refreshToken)
        .then((res) => {
          const userInfo = res.result;
          const tokenRq = `Bearer ${userInfo.token}`;
          const isRemember = !!authService.getRememberMeToken();

          axiosClient.defaults.headers.common['Authorization'] = tokenRq;
          originalRequest.headers['Authorization'] = tokenRq;

          store.dispatch(AUTHENTICATION_UPDATE_USER_INFO, { userInfo, isRemember });

          processQueue(null, userInfo.token);
          resolve(axiosClient(originalRequest));
        })
        .catch((err) => {
          processQueue(err, null);
          reject(error.response.data);
          authService.logout();
        })
        .then(() => {
          isRefreshing = false;
          if (!error?.config?.ignoreLoading) store.dispatch(SETTING_SET_LOADING, false);
        });
    });
  }

  if (
    error.response.status === STATUS_CODE.notFound ||
    error.response.status === STATUS_CODE.internalServerError
  ) {
    error.response.data = {
      message: ERROR_MSG_INTERNAL_SERVER_ERROR,
    };
  }

  if (!error?.config?.ignoreLoading) store.dispatch(SETTING_SET_LOADING, false);

  return Promise.reject(error.response.data);
};

export default axiosClient;
