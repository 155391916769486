<template>
  <v-btn
    :id="id"
    :class="className"
    :type="type"
    :block="block"
    :depressed="depressed"
    :outlined="outlined"
    :disabled="disabled"
    :aria-label="text"
    :ripple="ripple"
    @click="onClick"
  >
    <div v-if="loading && id === loadingId" class="d-flex align-center">
      <div class="lds-ring mr-3">
        <div></div>
        <div></div>
        <div></div>
      </div>
      <span class="btn-text text-bold">{{ text }}...</span>
    </div>
    <span v-else class="btn-text">
      <v-icon v-if="icon" class="icon__wrap bg-white">{{ icon }}</v-icon>
      {{ text }}
    </span>
  </v-btn>
</template>

<script>
import { mapGetters } from 'vuex';
import { SETTING_GET_LOADING, SETTING_GET_LOADING_ID } from '@/store/types/getter-types';
import { BUTTON_TYPE } from '@/constants/common.constants';
import { SETTING_SET_LOADING_ID, MESSAGE_CLEAR } from '@/store/types/action-types';

export default {
  name: 'BaseButton',
  props: {
    id: String,
    className: String,
    text: String,
    disabled: Boolean,
    invalid: Boolean,
    loading: Boolean,
    block: Boolean,
    depressed: Boolean,
    outlined: Boolean,
    type: String,
    icon: String,
    ripple: Boolean,
  },
  computed: {
    ...mapGetters({
      isLoading: SETTING_GET_LOADING,
      loadingId: SETTING_GET_LOADING_ID,
    }),
  },
  methods: {
    onClick() {
      if (this.type === BUTTON_TYPE.SUBMIT && !this.isLoading) {
        this.$store.dispatch(MESSAGE_CLEAR);
        if (this.invalid) {
          // Find the error field and focus on the first error
          const errorElm = document.querySelectorAll('.field-invalid')[0];

          if (errorElm) {
            const closestInputElm = errorElm.querySelector('input');
            if (closestInputElm) closestInputElm.focus();

            errorElm.scrollIntoView({ behavior: 'smooth' });
          }
        } else {
          const { dispatch } = this.$store;
          dispatch(SETTING_SET_LOADING_ID, this.id);
        }
      }
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss" scoped></style>
