import { MESSAGE_SUCCESS, MESSAGE_ERROR, MESSAGE_CLEAR } from '@/store/types/action-types';
import {
  MESSAGE_SUCCESS_MUTATION,
  MESSAGE_ERROR_MUTATION,
  MESSAGE_CLEAR_MUTATION,
} from '@/store/types/mutation-types';
import { MESSAGE_TYPE, MESSAGE_DISPLAY_TYPE } from '@/constants/common.constants';

export const message = {
  state: {
    type: MESSAGE_DISPLAY_TYPE.alert,
    message: null,
    displayType: null,
  },
  actions: {
    [MESSAGE_SUCCESS]({ commit }, msgInfo) {
      commit(MESSAGE_SUCCESS_MUTATION, msgInfo);
    },
    [MESSAGE_ERROR]({ commit }, msgInfo) {
      commit(MESSAGE_ERROR_MUTATION, msgInfo);
    },
    [MESSAGE_CLEAR]({ commit }) {
      commit(MESSAGE_CLEAR_MUTATION);
    },
  },
  mutations: {
    [MESSAGE_SUCCESS_MUTATION](state, { message, displayType }) {
      state.type = MESSAGE_TYPE.success;
      state.message = message;
      state.displayType = displayType;
    },
    [MESSAGE_ERROR_MUTATION](state, { message, displayType }) {
      state.type = MESSAGE_TYPE.error;
      state.message = message;
      state.displayType = displayType;
    },
    [MESSAGE_CLEAR_MUTATION](state) {
      state.type = null;
      state.message = null;
      state.displayType = null;
    },
  },
};
