import http from '@/common/http-common';
import {
  API_URL_ADD_POLICY,
  API_URL_GET_POLICIES,
  API_URL_GET_POLICY_BY_ID,
  API_URL_POLICY_RENEWAL,
  API_URL_DOWNLOAD_DOCUMENT,
  API_URL_DOWNLOAD_PULL,
  API_URL_POLICY_ACCEPT_RENEWAL,
  API_URL_PAYMENT_UPDATE_SELF_SERVE,
  API_URL_DOWNLOAD_RP_POLICIES,
  API_URL_GET_LETTING_AGENT_RP_POLICIES,
  API_URL_UPDATE_DELIVERY_PREFERENCES,
  API_URL_GET_POLICY_BY_CUSTOMER_EMAIL,
  API_URL_CHANGE_PAYMENT_DETAILS,
  API_URL_CONFIRM_PAYMENT,
} from '@/constants/api.constants';
import {
  PAYMENT_FREQUENCY,
  PAYMENT_TYPE,
  PRODUCT_CODES,
  SELF_SERVE_KEY,
  INSURED_PARTY,
} from '@/constants/common.constants';
import { getPolicyProgress, getInsuredAddress, getRemainingDays } from '@/common/utils';
import some from 'lodash-es/some';
import filter from 'lodash-es/filter';
import indexOf from 'lodash-es/indexOf';
import find from 'lodash-es/find';

class PolicyService {
  addPolicy(data) {
    return http.post(API_URL_ADD_POLICY, data);
  }

  getPolicies(params) {
    return http.get(API_URL_GET_POLICIES, { params });
  }

  getLettingAgentRPPolicies(params) {
    return http.get(API_URL_GET_LETTING_AGENT_RP_POLICIES, { params });
  }

  downloadRPPolicies(params) {
    return http.get(API_URL_DOWNLOAD_RP_POLICIES, { params });
  }

  getPolicyByRef(ref) {
    return http.get(`${API_URL_GET_POLICY_BY_ID}/${ref}`);
  }

  checkPolicyRenewal(data) {
    return http.post(API_URL_POLICY_RENEWAL, data);
  }

  acceptPolicyRenewal(data) {
    return http.post(API_URL_POLICY_ACCEPT_RENEWAL, data);
  }

  updateSelfServe(data) {
    return http.post(API_URL_PAYMENT_UPDATE_SELF_SERVE, data);
  }

  downloadDocument(documentId) {
    return http.get(`${API_URL_DOWNLOAD_DOCUMENT}/${documentId}`, { responseType: 'blob' });
  }

  documentPull() {
    return http.post(API_URL_DOWNLOAD_PULL);
  }

  updateDeliveryPreferences(data) {
    return http.post(API_URL_UPDATE_DELIVERY_PREFERENCES, data);
  }

  getPolicyByCustomerEmail(email) {
    return http.get(`${API_URL_GET_POLICY_BY_CUSTOMER_EMAIL}?email=${email}`);
  }

  updatePaymentDetails(data) {
    return http.post(API_URL_CHANGE_PAYMENT_DETAILS, data);
  }

  confirmPayment(data) {
    return http.post(API_URL_CONFIRM_PAYMENT, data);
  }

  checkPaymentCondition(policyDetails) {
    const paymentMethod = policyDetails.Payment.Method?.toLowerCase();
    const paymentFrequency = policyDetails.Payment.Frequency?.toLowerCase();
    const isAnnualCardPayers =
      paymentMethod === PAYMENT_TYPE.DEBIT_CARD?.toLowerCase() ||
      paymentMethod === PAYMENT_TYPE.CREDIT_CARD?.toLowerCase();
    const isAnnual = paymentFrequency === PAYMENT_FREQUENCY.ANNUAL?.toLowerCase();
    const isMonthly = paymentFrequency === PAYMENT_FREQUENCY.MONTHLY?.toLowerCase();
    const isDirectDebit = paymentMethod === PAYMENT_TYPE.DIRECT_DEBIT?.toLowerCase();

    const isShowPayNow =
      policyDetails.Renewal?.IsInRenewal &&
      !policyDetails.IsRenewalPaid &&
      !policyDetails.Renewal.Paid &&
      isAnnualCardPayers &&
      (isAnnual || policyDetails.IsHalfYear);

    const isShowAcceptRenewal =
      policyDetails.Renewal?.IsInRenewal &&
      policyDetails.Renewal.Paid &&
      !policyDetails.Renewal.Accepted &&
      !policyDetails.AutoRenew &&
      !policyDetails.IsRenewalAccepted &&
      isDirectDebit;

    const isClassic = this.isClassicProduct(policyDetails.ProductCode);
    const isCanEditPreferredDate = isClassic
      ? isDirectDebit
      : isDirectDebit && !policyDetails.Renewal?.IsInRenewal;

    // TODO: remove this function if support all types
    const isShowEditPaymentDetails =
      isDirectDebit &&
      this.isShowEditPaymentDetails(
        policyDetails.ProductCode,
        policyDetails?.Cover?.RentProtection?.InsuredParty,
      );

    return {
      isShowPayNow,
      isShowAcceptRenewal,
      isCanEditPreferredDate,
      isShowEditPaymentDetails,
      isAnnual,
      isMonthly,
    };
  }

  isClassicProduct(productCode) {
    const classicCodes = [
      PRODUCT_CODES.ClassicHome,
      PRODUCT_CODES.ClassicLandlords,
      PRODUCT_CODES.MPPI,
      PRODUCT_CODES.STIP,
    ];
    return indexOf(classicCodes, productCode) > -1;
  }

  // TODO: remove this function if support all types
  isShowEditPaymentDetails(productCode, insuredParty) {
    if (productCode === PRODUCT_CODES.RentProtection) {
      return insuredParty === INSURED_PARTY.PrivateLandlord;
    }

    return true;
  }

  buildPolicyDetails(policyDetails) {
    policyDetails.policyRef = policyDetails.policyRef?.toUpperCase();
    let jsonData = policyDetails.policyData.dataJson;

    // get remaining days
    switch (jsonData.ProductCode) {
      case PRODUCT_CODES.STIP:
        if (jsonData.AnnualReviewStatementDate) {
          jsonData.RemainingDays = getRemainingDays(jsonData.AnnualReviewStatementDate);
        }
        jsonData.BenefitPeriod = jsonData.Cover.Income.BenefitPeriod;
        break;
      case PRODUCT_CODES.MPPI:
        if (jsonData.AnnualReviewStatementDate) {
          jsonData.RemainingDays = getRemainingDays(jsonData.AnnualReviewStatementDate);
        }
        jsonData.BenefitPeriod = jsonData.Cover.Mortgage.BenefitPeriod;
        break;
      default:
        jsonData.RemainingDays = getRemainingDays(jsonData.PolicyEndDate);
        break;
    }

    // get insured code
    jsonData.Insurer.Code = jsonData.Insurer.Code || 'unknown';

    // get policy progress
    jsonData.Progress = getPolicyProgress(jsonData.PolicyStartDate, jsonData.PolicyEndDate);

    // get Insured Address
    jsonData.FormattedInsuredAddress = getInsuredAddress(jsonData.InsuredAddress);

    // check policy is half year
    jsonData.IsHalfYear = jsonData.PolicyPeriodMonths === 6;

    // check self serve value
    const selfServeValues = policyDetails.selfServeValues;
    jsonData.IsRenewalAccepted = some(selfServeValues, {
      key: SELF_SERVE_KEY.RENEWAL_ACCEPTED,
      value: 'true',
    });
    jsonData.IsRenewalPaid = some(selfServeValues, {
      key: SELF_SERVE_KEY.RENEWAL_PAID,
      value: 'true',
    });

    jsonData.UpdatePaymentDetailSelfServe = this.getUpdatePaymentDetailSelfServe(selfServeValues);

    // check payment condition
    const {
      isShowPayNow,
      isShowAcceptRenewal,
      isCanEditPreferredDate,
      isAnnual,
      isMonthly,
      isShowEditPaymentDetails,
    } = this.checkPaymentCondition(jsonData);
    jsonData.isShowPayNow = isShowPayNow;
    jsonData.isShowAcceptRenewal = isShowAcceptRenewal;
    jsonData.isCanEditPreferredDate = isCanEditPreferredDate;
    jsonData.isAnnual = isAnnual;
    jsonData.isMonthly = isMonthly;
    jsonData.isShowEditPaymentDetails = isShowEditPaymentDetails;

    // check expired soon
    jsonData.isExpiredSoon = isShowPayNow || isShowAcceptRenewal;

    // build excluded optional cover
    jsonData.ExcludedOptionalCover = filter(jsonData.AddOns, (x) => !x.Included).map(
      (x) => x.Title,
    );

    // get dd collection day
    if (jsonData.Payment) {
      jsonData.Payment.DirectDebitCollectionDay =
        parseInt(jsonData.UpdatePaymentDetailSelfServe?.DirectDebitCollectionDay) ||
        jsonData.Payment?.DirectDebitCollectionDay;
    }

    for (var key in jsonData.Cover) {
      if (!jsonData.Cover[key].Included) {
        const title =
          jsonData.ProductCode === PRODUCT_CODES.TenantsLiability ? 'Claims Limit' : key + ' Cover';
        jsonData.ExcludedOptionalCover.push(title); // TODO: update with new change from API
      }
    }

    return jsonData;
  }

  getUpdatePaymentDetailSelfServe(selfServeValues) {
    let updatePaymentDetailSelfServe = {};

    updatePaymentDetailSelfServe.DirectDebitCollectionDay = find(selfServeValues, {
      key: SELF_SERVE_KEY.DIRECT_DEBIT_COLLECTION_DAY,
    })?.value;

    updatePaymentDetailSelfServe.MaskedSortCode = find(selfServeValues, {
      key: SELF_SERVE_KEY.MASKED_SORT_CODE,
    })?.value;

    updatePaymentDetailSelfServe.MaskedAccountNumber = find(selfServeValues, {
      key: SELF_SERVE_KEY.MASKED_ACCOUNT_NUMBER,
    })?.value;

    updatePaymentDetailSelfServe.AccountName = find(selfServeValues, {
      key: SELF_SERVE_KEY.ACCOUNT_NAME,
    })?.value;

    return updatePaymentDetailSelfServe;
  }
}

export default new PolicyService();
