export const NAME_MIN_LENGTH = '12';
export const PASSWORD_MIN_LENGTH = '12';
export const PASSWORD_MAX_LENGTH = '128';
export const NAME_MAX_LENGTH = '100';
export const REFRESH_TOKEN_EXPIRES = '7d';
export const INVALID_CHARACTER_NAME = `\\/<>{}[]@#`;
export const MAX_AGE = 110;
export const MIN_AGE = 18;
export const DOCUMENT_PRODUCTION_DATE_LIMITATION = 180;
export const XS_SCREEN = 376;
export const IDLE_TIME = 1800000; // 30 mins

export const PS_DEFAULT_OPTIONS = {
  suppressScrollX: true,
};

export const PS_TABLE_OPTIONS = {
  suppressScrollY: true,
};

export const MESSAGE_TYPE = {
  success: 'success',
  error: 'error',
};

export const MESSAGE_DISPLAY_TYPE = {
  text: 'text',
  alert: 'alert',
};

export const PASSWORD_STRENGTH = {
  RED: 'red',
  ORANGE: 'orange',
  GREEN: 'green',
};

export const PASSWORD_STRENGTH_TEXT = {
  WEAK: 'Weak',
  MEDIUM: 'Medium',
  STRONG: 'Strong',
};

export const INPUT_TYPE = {
  TEXT: 'text',
  PASSWORD: 'password',
  NUMBER: 'number',
};

export const BUTTON_TYPE = {
  SUBMIT: 'submit',
};

export const PAYMENT_FREQUENCY = {
  MONTHLY: 'Monthly',
  ANNUAL: 'Annual',
};

export const PAYMENT_TYPE = {
  DIRECT_DEBIT: 'DirectDebit',
  BANK_TRANSFER: 'BankTransfer',
  DEBIT_CARD: 'DebitCard',
  CREDIT_CARD: 'CreditCard',
};

export const SELF_SERVE_KEY = {
  RENEWAL_ACCEPTED: 'RenewalAccepted',
  RENEWAL_PAID: 'RenewalPaid',
  DOCUMENT_DELIVERY_PREFERENCE: 'DocumentDeliveryPreference',
  MASKED_SORT_CODE: 'MaskedSortCode',
  MASKED_ACCOUNT_NUMBER: 'MaskedAccountNumber',
  DIRECT_DEBIT_COLLECTION_DAY: 'DirectDebitCollectionDay',
  ACCOUNT_NAME: 'AccountName',
};

export const TOKEN_PURPOSE = {
  RESET_PASSWORD: 'ResetPassword',
  EMAIL_CONFIRMATION: 'EmailConfirmation',
  COMPLETE_REGISTRATION: 'CompleteRegistration',
};

export const INSURER_TYPE = {
  AGEAS: 'ageas',
  ALLIANZ: 'allianz',
  ARC: 'arc',
  AVIVA: 'aviva',
  AXA: 'axa',
  GEO: 'geo',
  LEGAL: 'lg',
  LV_BROKER: 'lv',
  PRESTIGE: 'prestige',
  PROPERTY_GUARD: 'propertyguard',
  UK: 'rsa',
  RSA: 'ukg',
  ZURICH: 'zurich',
};

export const VENDOR_SYSTEM_KEY = {
  PAYMENTSHIELD: 'PAYMENTSHIELD',
};

export const POLICY_ITEMS_TYPE = {
  POLICY_DETAILS: 'PolicyDetails',
  PERSONAL_DETAILS: 'PersonalDetails',
  PAYMENT_DETAILS: 'PaymentDetails',
};

export const PRODUCT_CODE_ORDER = {
  ClassicHome: 1,
  PanelHome: 1,
  Panel2Home: 1,
  ClassicLandlords: 2,
  PanelLandlords: 2,
  Panel2Landlords: 2,
  TenantsContents: 3,
  TenantsLiability: 4,
  RentProtection: 5,
  MPPI: 6,
  STIP: 7,
};

export const PRODUCT_CODES = {
  ClassicHome: 'ClassicHome',
  PanelHome: 'PanelHome',
  Panel2Home: 'Panel2Home',
  ClassicLandlords: 'ClassicLandlords',
  PanelLandlords: 'PanelLandlords',
  Panel2Landlords: 'Panel2Landlords',
  TenantsContents: 'TenantsContents',
  TenantsLiability: 'TenantsLiability',
  RentProtection: 'RentProtection',
  MPPI: 'MPPI',
  STIP: 'STIP',
};

export const INSURED_PARTY = {
  LettingAgent: 'LettingAgent',
  PrivateLandlord: 'PrivateLandlord',
};

export const POLICY_STATUS = {
  ACTIVE: 'ACTIVE',
  CANCELLED: 'CANCELLED',
};

export const COVER_COMPONENTS = {
  Buildings: 'BCCover',
  Contents: 'BCCover',
  Mortgage: 'PersonalCover',
  Income: 'PersonalCover',
  TenantsLiability: 'TLICover',
  RentProtection: 'RPCover',
};

export const COVER_DISPLAY_TYPES = {
  DETAILS: 'DETAILS',
  OVERVIEW: 'OVERVIEW',
  SUMMARY: 'SUMMARY',
};

export const ADD_ONS_CODE = {
  LEGAL_EXPENSE: 'le',
  HOME_EMERGENCY: 'he',
};

export const DIALOG_TYPE = {
  TERMS_AND_CONDITIONS: 'TermsAndConditions',
  FAIR_PROCESSING_NOTICE: 'FairProcessingNotice',
  ACCESSIBILITY: 'ACCESSIBILITY',
  MODERN_SLAVERY_ACT: 'ModernSlaveryAct',
  COOKIES: 'COOKIES',
};

export const EMAIL_PURPOSE = {
  ChangeEmail: 'ChangeEmail',
  RegisterAccount: 'RegisterAccount',
  Login: 'Login',
};

export const ACCOUNT_TYPE = {
  CustomerPortal: 'CustomerPortal',
  CustomerHub: 'CustomerHub',
  NewBusiness: 'NewBusiness',
};

export const USER_TYPE = {
  ADVISER: 'ADVISER',
  POLICYHOLDER: 'POLICYHOLDER',
};

export const REDIRECT_TO_HOME_WITH_LOGGED_USER_PAGES = [
  'Login',
  'ForgotPassword',
  'ActiveAccount',
  'CreateNewPassword',
  'ChangeEmailVerify',
  'PortalRegister',
];

export const COMPANY_NAME = 'CompanyName';

export const DEFAULT_PAGING_SETTING = {
  PageSize: 5,
};

export const RP_POLICY_PAGING_SETTING = {
  PageSize: 15,
};

export const DOCUMENT_DELIVERY_PREFERENCE_TYPE = {
  Online: 'Online',
  Post: 'Posted',
};

export const PAYMENT_STATUS = {
  Failure: 'failure',
  Cancel: 'cancel',
};

export const COMMUNICATION_TYPE_KEY = {
  Forgot_Password: 'Forgotten password',
  Confirm_Email: 'Verify email address',
  Email_Confirmed: 'Email verified successfully',
  Verify_NewEmail: 'Confirm email change',
  Notification_EmailUpdated: 'Email changed',
  Change_Password: 'Password changed',
  Remove_Account: 'Account removed',
  Complete_Registration: 'Registration complete',
  Policy_Renewal: 'Policy renewal',
  Policy_Cancellation: 'Policy cancellation',
  Policy_Arrears: 'Policy arrears',
  Policy_Letter_Mta: 'Policy MTA letter',
  Policy_AnnualReview: 'Policy renewal',
  Policy_Renewal_Reminder: 'Renewal reminder',
  New_Business_Register: 'New registration',
  Existing_User_NewPolicy: 'New policy(existing user)',
};

export const EMAIL_STATUS = {
  Sent: 'Sent',
  Delivery: 'Delivered',
  Open: 'Opened',
  Bounce: 'Bounced',
};

export const PREFERRED_PAYMENT_DATE = [
  { value: 1, name: '1st of the month' },
  { value: 7, name: '7th of the month' },
  { value: 14, name: '14th of the month' },
  { value: 21, name: '21st of the month' },
  { value: 24, name: '24th of the month' },
];

export const UPDATE_PAYMENT_STATUS = {
  Confirm: 'Confirm',
  OK: 'OK',
};

export const HIDE_NCD_POLICY_BOOKLET_REF = ['RSA/PS/004'];
