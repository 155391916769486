import authService from '@/services/auth.service';
import Vue from 'vue';
import VueRouter from 'vue-router';
import { AUTHENTICATION_REFRESH_TOKEN } from '@/store/types/action-types';
import { USER_TYPE, REDIRECT_TO_HOME_WITH_LOGGED_USER_PAGES } from '@/constants/common.constants';
import {
  HOME_PAGE_URL,
  ADVISER_HOME_PAGE_URL,
  PAGE_NOT_FOUND_PAGE_URL,
  LOGIN_PAGE_URL,
} from '@/constants/page.constants';
import store from '@/store';
import isEmpty from 'lodash-es/isEmpty';
import indexOf from 'lodash-es/indexOf';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'SiteUnderMaintenance',
    meta: { title: 'Paymentshield | Site Under Maintenance' },
    component: () => import('@/views/Error/UnderMaintenanceView.vue'),
  },
  {
    path: '/contact-us',
    name: 'ContactUs',
    meta: { title: 'Paymentshield | Contact Us' },
    component: () => import('@/views/ContactUs/ContactUsView.vue'),
  },
  { path: '*', redirect: '/' },
];

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(() => {});
};

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;

  let token = authService.getToken();
  const rememberMeToken = authService.getRememberMeToken();

  if (!token && rememberMeToken) {
    store
      .dispatch(AUTHENTICATION_REFRESH_TOKEN)
      .then(
        () => {},
        () => {},
      )
      .finally(() => {
        redirectHandler(to, next);
      });
  } else {
    redirectHandler(to, next);
  }
});

const redirectHandler = (to, next) => {
  const token = authService.getToken();
  const { requiresAuth, requiresPermission } = to.meta;
  const userType = authService.getUserType();

  if (!isEmpty(token)) {
    if (indexOf(REDIRECT_TO_HOME_WITH_LOGGED_USER_PAGES, to.name) > -1) {
      const redirectUrl =
        userType === USER_TYPE.POLICYHOLDER ? HOME_PAGE_URL : ADVISER_HOME_PAGE_URL;
      next({ path: redirectUrl });
    } else if (requiresPermission && indexOf(requiresPermission, userType) < 0) {
      next({ path: PAGE_NOT_FOUND_PAGE_URL });
    } else {
      next();
    }
  } else {
    if (requiresAuth) {
      return next({ path: LOGIN_PAGE_URL });
    }
    next();
  }
};

export default router;
