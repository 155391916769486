import Vue from 'vue';
import router from '@/router';
import userService from '@/services/user.service';
import authService from '@/services/auth.service';
import {
  USER_REGISTER,
  USER_ACTIVE_ACCOUNT,
  MESSAGE_ERROR,
  MESSAGE_SUCCESS,
  USER_CHANGE_PASSWORD,
  USER_CHANGE_EMAIL,
  USER_CHANGE_EMAIL_VERIFY,
  USER_FORGOT_PASSWORD,
  USER_CREATE_NEW_PASSWORD,
  USER_VALIDATE_TOKEN,
  USER_CHECK_EMAIL,
  USER_RESEND_EMAIL,
  USER_COMPLETE_REGISTRATION,
  USER_CHANGE_NAME,
  AUTHENTICATION_UPDATE_USER_INFO,
} from '@/store/types/action-types';
import { AUTHENTICATION_LOGOUT_SUCCESS } from '@/store/types/mutation-types';
import {
  REGISTER_SUCCESS_PAGE_URL,
  LOGIN_PAGE_URL,
  CHANGE_EMAIL_SUMMARY_PAGE_URL,
  FORGOT_PASSWORD_CHECK_EMAIL,
  ACCOUNT_SETTING_PAGE_URL,
} from '@/constants/page.constants';
import { USER_INFO } from '@/constants/key.constants';

export const user = {
  state: {},
  actions: {
    [USER_REGISTER]({ dispatch }, data) {
      userService
        .register(data)
        .then(() => {
          router.push(REGISTER_SUCCESS_PAGE_URL);
        })
        .catch((err) => {
          dispatch(MESSAGE_ERROR, {
            message: err.message,
          });
          window.scrollTo({ top: 0, behavior: 'smooth' });
        });
    },
    [USER_ACTIVE_ACCOUNT]({ dispatch }, { email, token }) {
      userService
        .activeAccount({ email, token })
        .then((res) => {
          if (res && res.isSuccess) {
            dispatch(MESSAGE_SUCCESS, {
              message: res.message,
            });
            router.push(LOGIN_PAGE_URL);
          }
        })
        .catch((err) => {
          dispatch(MESSAGE_ERROR, { message: err.message });
          router.push(LOGIN_PAGE_URL);
        });
    },
    [USER_CHANGE_PASSWORD]({ dispatch }, { currentPassword, newPassword, confirmNewPassword }) {
      userService
        .changePassword({ currentPassword, newPassword, confirmNewPassword })
        .then((res) => {
          if (res && res.isSuccess) {
            router.push(ACCOUNT_SETTING_PAGE_URL);
            setTimeout(() => {
              dispatch(MESSAGE_SUCCESS, { message: res.message });
            });
          }
        })
        .catch((err) => {
          dispatch(MESSAGE_ERROR, { message: err.message });
          window.scrollTo({ top: 0, behavior: 'smooth' });
        });
    },
    [USER_CHANGE_NAME]({ dispatch }, { firstName, lastName }) {
      userService
        .changeName({ firstName, lastName })
        .then((res) => {
          if (res && res.isSuccess) {
            // update user info
            const isRemember = !!authService.getRememberMeToken();
            const userInfo = Vue.$cookies.get(USER_INFO);
            userInfo.firstName = firstName;
            userInfo.lastName = lastName;
            Vue.$cookies.set(USER_INFO, userInfo);
            dispatch(AUTHENTICATION_UPDATE_USER_INFO, { userInfo, isRemember });

            router.push(ACCOUNT_SETTING_PAGE_URL);
            setTimeout(() => {
              dispatch(MESSAGE_SUCCESS, { message: res.message });
            });
          }
        })
        .catch((err) => {
          dispatch(MESSAGE_ERROR, { message: err.message });
          window.scrollTo({ top: 0, behavior: 'smooth' });
        });
    },
    [USER_CHANGE_EMAIL]({ commit, dispatch }, { newEmail, isUpdateByAdviser, policyHolderEmail }) {
      userService
        .changeEmail({ newEmail, isUpdateByAdviser, policyHolderEmail })
        .then((res) => {
          if (res && res.isSuccess) {
            if (!isUpdateByAdviser) {
              authService.clearUserData();
              commit(AUTHENTICATION_LOGOUT_SUCCESS);
            }

            router.push(CHANGE_EMAIL_SUMMARY_PAGE_URL);
          }
        })
        .catch((err) => {
          dispatch(MESSAGE_ERROR, { message: err.message });
          window.scrollTo({ top: 0, behavior: 'smooth' });
        });
    },
    [USER_CHANGE_EMAIL_VERIFY]({ dispatch, commit }, verifyData) {
      userService
        .verifyEmail(verifyData)
        .then((res) => {
          if (res && res.isSuccess) {
            authService.clearUserData();
            commit(AUTHENTICATION_LOGOUT_SUCCESS);
            dispatch(MESSAGE_SUCCESS, { message: res.message });
            router.push(LOGIN_PAGE_URL);
          }
        })
        .catch((err) => {
          authService.clearUserData();
          commit(AUTHENTICATION_LOGOUT_SUCCESS);
          dispatch(MESSAGE_ERROR, { message: err.message });
          router.push(LOGIN_PAGE_URL);
        });
    },
    [USER_FORGOT_PASSWORD]({ dispatch }, email) {
      userService
        .forgotPassword(email)
        .then((res) => {
          if (res && res.isSuccess) {
            router.push(FORGOT_PASSWORD_CHECK_EMAIL);
          }
        })
        .catch((err) => {
          dispatch(MESSAGE_ERROR, { message: err.message });
        });
    },
    [USER_CREATE_NEW_PASSWORD]({ dispatch }, data) {
      userService
        .createNewPassword(data)
        .then((res) => {
          if (res && res.isSuccess) {
            setTimeout(() => {
              dispatch(MESSAGE_SUCCESS, { message: res.message });
            }, 200);
            router.push(LOGIN_PAGE_URL);
          }
        })
        .catch((err) => {
          dispatch(MESSAGE_ERROR, { message: err.message });
          window.scrollTo({ top: 0, behavior: 'smooth' });
        });
    },
    async [USER_RESEND_EMAIL]({ dispatch }, { reqData }) {
      return userService
        .userResendEmail(reqData)
        .then((res) => {
          if (res && res.isSuccess) {
            dispatch(MESSAGE_SUCCESS, { message: res.message });
          }
          return res;
        })
        .catch((err) => {
          dispatch(MESSAGE_ERROR, { message: err.message });
          window.scrollTo({ top: 0, behavior: 'smooth' });
          return err;
        });
    },
    async [USER_VALIDATE_TOKEN]({ dispatch }, { email, token, purpose }) {
      return userService
        .validateToken({ email, token, purpose })
        .then((res) => {
          return res;
        })
        .catch((err) => {
          setTimeout(() => {
            dispatch(MESSAGE_ERROR, { message: err.message });
          }, 200);
          router.push(LOGIN_PAGE_URL);
          return err;
        });
    },
    async [USER_CHECK_EMAIL](commit, { email, emailPurpose }) {
      return userService
        .checkEmail({ email, emailPurpose })
        .then((res) => {
          return res;
        })
        .catch((err) => {
          return err;
        });
    },
    [USER_COMPLETE_REGISTRATION]({ dispatch }, data) {
      userService
        .completeRegistration(data)
        .then(() => {
          router.push(REGISTER_SUCCESS_PAGE_URL);
        })
        .catch((err) => {
          dispatch(MESSAGE_ERROR, {
            message: err.message,
          });
          window.scrollTo({ top: 0, behavior: 'smooth' });
        });
    },
  },
};
