import Vue from 'vue';
import Vuex from 'vuex';
import { authentication } from '@/store/modules/authentication.module';
import { user } from '@/store/modules/user.module';
import { setting } from '@/store/modules/setting.module';
import { message } from '@/store/modules/message.module';
import { policy } from '@/store/modules/policy.module';
import { adviser } from '@/store/modules/adviser.module';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    authentication,
    user,
    setting,
    message,
    policy,
    adviser,
  },
});
