import http from '@/common/http-common';
import { API_URL_BUSINESS_TIMES } from '@/constants/api.constants';

class SettingService {
  getBusinessTimes() {
    return http.get(API_URL_BUSINESS_TIMES);
  }
}

export default new SettingService();
