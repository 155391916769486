export const SETTING_GET_BG_STATUS = 'CONTROL_GET_BG_STATUS';
export const SETTING_GET_GREETING = 'SETTING_GET_GREETING';
export const SETTING_GET_LOADING = 'SETTING_GET_LOADING';
export const SETTING_GET_REQ_DATA = 'SETTING_GET_REQ_DATA';
export const SETTING_GET_LOADING_ID = 'SETTING_GET_LOADING_ID';

export const AUTHENTICATION_GET_USER_INFO = 'AUTHENTICATION_GET_USER_INFO';
export const AUTHENTICATION_GET_LOGGED_IN = 'AUTHENTICATION_GET_LOGGED_IN';

export const POLICY_GET_POLICIES_ACTIVE = 'POLICY_GET_POLICIES_ACTIVE';
export const POLICY_GET_POLICIES_CANCELLED = 'POLICY_GET_POLICIES_CANCELLED';
export const POLICY_GET_RP_POLICIES_ACTIVE = 'POLICY_GET_RP_POLICIES_ACTIVE';
export const POLICY_GET_RP_POLICIES_CANCELLED = 'POLICY_GET_RP_POLICIES_CANCELLED';
export const POLICY_GET_POLICY_DETAILS = 'POLICY_GET_POLICY_DETAILS';

export const ADVISER_GET_CURRENT_SEARCHED_USER = 'ADVISER_GET_CURRENT_SEARCHED_USER';
