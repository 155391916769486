export const API_URL_LOGIN = 'api/user/authenticate';
export const API_URL_REGISTER = 'api/user/register';
export const API_URL_ACTIVE_ACCOUNT = 'api/user/active-account';
export const API_URL_REFRESH_TOKEN = 'api/user/refresh-token';
export const API_URL_FORGOT_PASSWORD = 'api/user/forgot-password';
export const API_URL_RESET_PASSWORD = 'api/user/reset-password';
export const API_URL_CHANGE_PASSWORD = 'api/user/change-password';
export const API_URL_CHANGE_EMAIL = 'api/user/change-email';
export const API_URL_VERIFY_EMAIL = 'api/user/verify-email';
export const API_URL_CHANGE_NAME = 'api/user/change-name';
export const API_URL_GET_CURRENT_USER = 'api/User/current-user';
export const API_URL_ADD_POLICY = 'api/policy/add';
export const API_URL_GET_POLICIES = 'api/policies';
export const API_URL_GET_POLICY_BY_ID = 'api/policy';
export const API_URL_POLICY_RENEWAL = 'api/policy/renewal';
export const API_URL_POLICY_ACCEPT_RENEWAL = 'api/policy/accept-renewal';
export const API_URL_PAYMENT_UPDATE_SELF_SERVE = 'api/policy/payment-success';
export const API_URL_VALIDATE_TOKEN = 'api/user/validate-token';
export const API_URL_CHECK_EMAIL = 'api/user/check-email';
export const API_URL_USER_RESEND_EMAIL = 'api/user/resend-email';
export const API_URL_COMPLETE_REGISTRATION = 'api/user/complete-registration';
export const API_URL_DOWNLOAD_DOCUMENT = 'api/PolicyDocument';
export const API_URL_DOWNLOAD_PULL = 'api/PolicyDocument/pull';
export const API_URL_DOWNLOAD_RP_POLICIES = 'api/policies/RPCSV';
export const API_URL_GET_LETTING_AGENT_RP_POLICIES = 'api/policies/letting-agent';
export const API_URL_BUSINESS_TIMES = 'api/BusinessTime/business-times';
export const API_URL_UPDATE_DELIVERY_PREFERENCES = 'api/PolicyDocument/change-preferences';
export const API_URL_GET_POLICY_BY_CUSTOMER_EMAIL = 'api/Adviser/policy';
export const API_URL_GET_USER_BY_CUSTOMER_EMAIL = 'api/Adviser/user';
export const API_URL_ADVISER_GET_RESEND_EMAIL = 'api/sentcommunications';
export const API_URL_ADVISER_RESEND_EMAIL = 'api/sentcommunications/re-sent';
export const API_URL_CHANGE_PAYMENT_DETAILS = 'api/policy/payment';
export const API_URL_CONFIRM_PAYMENT = 'api/policy/confirm-payment';
export const API_URL_CHANGE_EMAIL_BY_ADVISER = 'api/adviser/user/change-email';
