import format from 'string-format';
import moment from 'moment';
import {
  ERROR_MSG_PASS_LENGTH_MIN,
  ERROR_MSG_PASS_LENGTH_MAX,
  ERROR_MSG_REGEX_NAME,
  ERROR_MSG_NAME_LENGTH_MAX,
  ERROR_MSG_INVALID_FORMAT,
  ERROR_MSG_CONFIRM,
  ERROR_MSG_NEW_PASSWORD_MATCH_EXISTING_PASSWORD,
  ERROR_MSG_EMAIL_ADDRESSES_DO_NOT_MATCH,
  ERROR_MSG_DUPLICATE_EMAIL,
  TEXT_BOLD_CONTENT,
} from '@/constants/message.constants';
import {
  PASSWORD_MIN_LENGTH,
  PASSWORD_MAX_LENGTH,
  NAME_MAX_LENGTH,
  INVALID_CHARACTER_NAME,
} from '@/constants/common.constants';
import {
  DATE_FORMAT_YYYYMMDD,
  DATE_FORMAT_DDMMMMYYYY,
  DATE_FORMAT_DDMMYYYY,
} from '@/constants/date.constants';
import compact from 'lodash-es/compact';
import join from 'lodash-es/join';

export const customMessagesPassword = () => {
  return {
    max: format(ERROR_MSG_PASS_LENGTH_MAX, PASSWORD_MAX_LENGTH),
    min: format(ERROR_MSG_PASS_LENGTH_MIN, PASSWORD_MIN_LENGTH),
    pws: format(ERROR_MSG_PASS_LENGTH_MIN, PASSWORD_MIN_LENGTH),
    confirm: ERROR_MSG_CONFIRM,
    exist: ERROR_MSG_NEW_PASSWORD_MATCH_EXISTING_PASSWORD,
  };
};

export const customMessageName = () => {
  return {
    regex: format(ERROR_MSG_REGEX_NAME, INVALID_CHARACTER_NAME),
    max: format(ERROR_MSG_NAME_LENGTH_MAX, NAME_MAX_LENGTH),
    utf8: format(ERROR_MSG_INVALID_FORMAT, 'your name'),
  };
};

export const customMessageEmail = (uniqMsg) => {
  return {
    confirm: ERROR_MSG_EMAIL_ADDRESSES_DO_NOT_MATCH,
    uniq: uniqMsg || ERROR_MSG_DUPLICATE_EMAIL,
  };
};

export const generateGreetings = () => {
  var currentHour = moment().format('HH');

  if (currentHour >= 0 && currentHour < 12) {
    return 'Good morning';
  } else if (currentHour >= 12 && currentHour < 17) {
    return 'Good afternoon';
  } else {
    return 'Good evening';
  }
};

export const getRemainingDays = (endDate) => {
  let days =
    moment(endDate, DATE_FORMAT_YYYYMMDD).diff(moment().format(DATE_FORMAT_YYYYMMDD), 'days') + 1;
  days = days < 1 ? 0 : days;

  return days;
};

export const getPolicyProgress = (startDate, endDate) => {
  const totalDays = moment(endDate, DATE_FORMAT_YYYYMMDD).diff(startDate, 'days');
  const days = moment(new Date(), DATE_FORMAT_YYYYMMDD).diff(startDate, 'days');

  return (days / totalDays) * 100;
};

export const updateHelpshiftConfig = (user) => {
  window.helpshiftConfig.userId = user ? user.userId : null;
  window.helpshiftConfig.userName = user ? `${user.firstName + ' ' + user.lastName}` : null;
  window.Helpshift('updateHelpshiftConfig');
};

export const togglePageScrollbar = (isOpen) => {
  document.getElementsByTagName('html')[0].classList.toggle('hide-scroll', isOpen);
};

export const getInsuredAddress = (insuredAddress) => {
  if (insuredAddress && insuredAddress.Included) {
    let address = [];
    if (insuredAddress && insuredAddress.Lines.length > 0) {
      address = compact([...insuredAddress.Lines, insuredAddress.Postcode]);
    }

    return join(address, ', ');
  }
  return null;
};

export const getNumberWithOrdinal = (n) => {
  const s = ['th', 'st', 'nd', 'rd'],
    v = n % 100;

  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

export const getFileNameWithExtension = (type, name) => {
  switch (type) {
    case 'application/pdf':
      return `${name}.pdf`;
    case 'text/plain':
      return `${name}.txt`;
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return `${name}.docx`;
    case 'application/msword':
      return `${name}.doc`;
    default:
      return `${name}.txt`;
  }
};

export const formatConfirmPaymentMessage = (message) => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const ddDate = ['1st', '7th', '14th', '21st', '24th'];
  message = message.replace(/\n|\r/g, ' ');
  let words = message.split(' ');
  let result = '';
  for (let i = 0; i < words.length; i++) {
    // Bold currency and DD date
    if (words[i].includes('£') || ddDate.includes(words[i])) {
      result += format(TEXT_BOLD_CONTENT, words[i]);
    }
    // break lines after : character
    else if (words[i].includes(':')) {
      result += words[i] + `</br></br>`;
    }
    // Bold date time, example: 1 August 2022
    else if (months.includes(words[i + 1])) {
      const fullDate = format(
        TEXT_BOLD_CONTENT,
        words[i] + ' ' + words[i + 1] + ' ' + words[i + 2],
      );
      result += fullDate + ' ';
      i = i + 2;
    }
    // Bold date time, example: 1/1/2022
    else if (words[i].includes('/')) {
      const date = moment(words[i], DATE_FORMAT_DDMMYYYY);
      if (date.isValid()) {
        words[i] = date.format(DATE_FORMAT_DDMMMMYYYY);
        result += format(TEXT_BOLD_CONTENT, words[i]);
      }
    } else {
      result += words[i] + ' ';
    }
  }
  // Bold the rest of sentence, example: £20.5 on each consecutive month or 1st of each consecutive month
  const restString = ['on each consecutive month', 'of each consecutive month.'];
  for (const item of restString) {
    if (result.includes(item)) {
      result = result.replace(item, format(TEXT_BOLD_CONTENT, item));
    }
  }

  return result;
};
