import http from '@/common/http-common';
import Vue from 'vue';
import router from '@/router';
import { API_URL_LOGIN, API_URL_REFRESH_TOKEN } from '@/constants/api.constants';
import {
  TOKEN,
  REFRESH_TOKEN,
  REMEMBER_ME_TOKEN,
  USER_INFO,
  CURRENT_SEARCHED_USER,
} from '@/constants/key.constants';
import { REFRESH_TOKEN_EXPIRES } from '@/constants/common.constants';
import { LOGIN_PAGE_URL } from '@/constants/page.constants';
import { updateHelpshiftConfig } from '@/common/utils';

class AuthService {
  getToken() {
    return Vue.$cookies.get(TOKEN);
  }

  getRefreshToken() {
    return Vue.$cookies.get(REFRESH_TOKEN);
  }

  getRememberMeToken() {
    return Vue.$cookies.get(REMEMBER_ME_TOKEN);
  }

  getUserType() {
    const userInfo = Vue.$cookies.get(USER_INFO);
    return userInfo?.userTypeKey;
  }

  login(account) {
    return http.post(API_URL_LOGIN, account);
  }

  logout() {
    this.clearUserData();
    router.push(LOGIN_PAGE_URL);
  }

  refreshToken(refreshToken) {
    return http.post(API_URL_REFRESH_TOKEN, { refreshToken });
  }

  setUserData(userInfo, isRemember) {
    const { token, refreshToken } = userInfo;
    Vue.$cookies.set(USER_INFO, userInfo, { expire: 0 });
    Vue.$cookies.set(TOKEN, token, { expire: 0 });
    Vue.$cookies.set(REFRESH_TOKEN, refreshToken, { expire: 0 });
    if (isRemember) {
      Vue.$cookies.set(REMEMBER_ME_TOKEN, refreshToken, REFRESH_TOKEN_EXPIRES);
    }

    updateHelpshiftConfig(userInfo);
  }

  clearUserData() {
    Vue.$cookies.remove(USER_INFO);
    Vue.$cookies.remove(TOKEN);
    Vue.$cookies.remove(REFRESH_TOKEN);
    Vue.$cookies.remove(REMEMBER_ME_TOKEN);
    Vue.$cookies.remove(CURRENT_SEARCHED_USER);
    updateHelpshiftConfig();
  }
}

export default new AuthService();
