export const AUTHENTICATION_LOGIN = 'AUTHENTICATION_LOGIN';
export const AUTHENTICATION_LOGOUT = 'AUTHENTICATION_LOGOUT';
export const AUTHENTICATION_REFRESH_TOKEN = 'AUTHENTICATION_REFRESH_TOKEN';
export const AUTHENTICATION_UPDATE_USER_INFO = 'AUTHENTICATION_UPDATE_USER_INFO';

export const USER_REGISTER = 'USER_REGISTER';
export const USER_ACTIVE_ACCOUNT = 'USER_ACTIVE_ACCOUNT';
export const USER_FORGOT_PASSWORD = 'USER_FORGOT_PASSWORD';
export const USER_CREATE_NEW_PASSWORD = 'USER_CREATE_NEW_PASSWORD';
export const USER_CHANGE_PASSWORD = 'USER_CHANGE_PASSWORD';
export const USER_CHANGE_EMAIL = 'USER_CHANGE_EMAIL';
export const USER_CHANGE_EMAIL_VERIFY = 'USER_CHANGE_EMAIL_VERIFY';
export const USER_VALIDATE_TOKEN = 'USER_VERIFY_TOKEN';
export const USER_CHECK_EMAIL = 'USER_CHECK_EMAIL';
export const USER_RESEND_EMAIL = 'USER_RESEND_EMAIL';
export const USER_COMPLETE_REGISTRATION = 'USER_COMPLETE_REGISTRATION';
export const USER_CHANGE_NAME = 'USER_CHANGE_NAME';

export const SETTING_SET_LOADING = 'SETTING_SET_LOADING';
export const SETTING_SET_GREETING = 'SETTING_SET_GREETING';
export const SETTING_SET_REQ_DATA = 'SETTING_SET_REQ_DATA';
export const SETTING_SET_LOADING_ID = 'SETTING_SET_LOADING_ID';
export const SETTING_GET_BUSINESS_TIMES = 'SETTING_GET_BUSINESS_TIMES';

export const MESSAGE_SUCCESS = 'MESSAGE_SUCCESS';
export const MESSAGE_ERROR = 'MESSAGE_ERROR';
export const MESSAGE_CLEAR = 'MESSAGE_CLEAR';

export const POLICY_GET_POLICIES = 'POLICY_GET_POLICIES';
export const POLICY_ADD = 'POLICY_ADD';
export const POLICY_GET_BY_REF = 'POLICY_GET_BY_REF';
export const POLICY_CHECK_RENEWAL = 'POLICY_CHECK_RENEWAL';
export const POLICY_DOWNLOAD_DOCUMENT = 'POLICY_DOWNLOAD_DOCUMENT';
export const POLICY_DOCUMENT_PULL = 'POLICY_DOCUMENT_PULL';
export const POLICY_ACCEPT_RENEWAL = 'POLICY_ACCEPT_RENEWAL';
export const POLICY_PAYMENT_UPDATE_SELF_SERVE = 'POLICY_PAYMENT_UPDATE_SELF_SERVE';
export const POLICY_UPDATE_POLICY_DETAILS = 'POLICY_UPDATE_POLICY_DETAILS';
export const POLICY_DOWNLOAD_RP_POLICIES = 'POLICY_DOWNLOAD_RP_POLICIES';
export const POLICY_GET_LETTING_AGENT_RP_POLICIES = 'POLICY_GET_LETTING_AGENT_RP_POLICIES';
export const POLICY_UPDATE_DELIVERY_PREFERENCES = 'POLICY_UPDATE_DELIVERY_PREFERENCES';
export const POLICY_PAYMENT_UPDATE_DETAILS = 'POLICY_PAYMENT_UPDATE_DETAILS';
export const POLICY_PAYMENT_CONFIRM_PAYMENT = 'POLICY_PAYMENT_CONFIRM_PAYMENT';

export const ADVISER_SET_CURRENT_SEARCHED_USER = 'ADVISER_SET_CURRENT_SEARCHED_USER';
export const ADVISER_GET_RESEND_EMAIL = 'ADVISER_GET_RESEND_EMAIL';
export const ADVISER_RE_SEND_EMAIL = 'ADVISER_RE_SEND_EMAIL';
